import {
    APIEvent,
    EventEvent,
    LogLevel,
    TransportItem,
    faro,
} from '@grafana/faro-react'

export const logError = (
    error: Error,
    options?: {
        type?: string
        componentStack?: string
        context?: Record<string, string>
    }
) => {
    faro.api.pushError(error, {
        type: options?.type ?? 'Custom Error',
        context: {
            message: error.message,
            ...(options?.componentStack
                ? { componentStackTrace: options.componentStack }
                : {}),
            ...options?.context,
        },
    })
}

export const logEvent = (event: string) => {
    faro.api.pushEvent(event)
}

export const log = (
    log: string,
    options?: {
        level?: LogLevel
        context?: Record<string, string>
    }
) => {
    faro.api.pushLog([log], {
        level: options?.level ?? LogLevel.INFO,
        context: options?.context,
    })
}

export const obfuscateEvent = (item: TransportItem<APIEvent>) => {
    if (item.type === 'event') {
        const payload = (item as TransportItem<EventEvent>).payload
        const httpUrl = payload.attributes?.['http.url']
            ? new URL(payload.attributes?.['http.url'])
            : null

        // replace path parameters in the API call URL
        if (httpUrl) {
            const replacements = [
                {
                    searchRegexp: /(lifen\/patient\/name\/)(.*)/,
                    replaceWith: '$1***',
                },
            ]
            const pathname = httpUrl.pathname
            for (const replacement of replacements) {
                httpUrl.pathname = pathname.replace(
                    new RegExp(replacement.searchRegexp),
                    replacement.replaceWith
                )
            }

            return {
                ...item,
                payload: {
                    ...payload,
                    attributes: {
                        ...payload.attributes,
                        'http.url': httpUrl.toString(),
                    },
                },
            }
        }
    }

    // replace parameters in the browser URL
    if (item.meta.page?.url) {
        const queryParamsToFilter = ['firstName', 'lastName', 'identifier']

        const pageURL = new URL(item.meta.page?.url)

        const obfuscatedSearchParams = pageURL.searchParams

        for (const queryParam of queryParamsToFilter) {
            if (obfuscatedSearchParams.get(queryParam)) {
                obfuscatedSearchParams.set(queryParam, '***')
            }
        }

        return {
            ...item,
            meta: {
                ...item.meta,
                page: {
                    url: `${pageURL.toString()}`,
                },
            },
        }
    }
    return item
}
