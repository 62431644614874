import { Locale } from 'src/tools/Helpers/Constants/Locale'

export const getLocaleFromLocalStorage = (): Locale | undefined => {
    const originLocale =
        localStorage.getItem('locale') ?? localStorage.getItem('language')

    const locale = Locale.safeParse(originLocale)

    if (locale) {
        if (originLocale !== locale.value || localStorage.getItem('language')) {
            // means it holds the old format we don't support anymore
            localStorage.setItem('locale', locale.value)
        }
    }

    // remove the language key if it exists as it has been either copied to locale key or ignored if badly formatted
    localStorage.removeItem('language')

    return locale
}

export const setLocaleToLocalStorage = (locale: Locale): void => {
    localStorage.setItem('locale', locale.value)
}
