import { z } from 'zod'
import { setLocaleToLocalStorage } from 'src/app/commons/toolsbox/getLocaleFromLocalStorage'

export class Locale {
    static readonly supportedLang = z.enum(['fr', 'en', 'nl', 'it', 'es', 'de'])
    static readonly supportedRegion = z.enum([
        'FR',
        'CA',
        'US',
        'GB',
        'BE',
        'IT',
        'ES',
        'DE',
    ])

    private constructor(
        private _lang: z.infer<typeof Locale.supportedLang>,
        private _region: z.infer<typeof Locale.supportedRegion>
    ) {
        this._lang = _lang
        this._region = _region
    }

    get value() {
        return `${this._lang}-${this._region}`
    }

    get lang() {
        return this._lang
    }

    get region() {
        return this._region
    }

    static safeParse(rawLocale: unknown) {
        try {
            // we replace _ with - to handle old formats coming for example from the local storage
            const stringLocale = z.string().parse(rawLocale).replace('_', '-')

            const splittedLocale = stringLocale.split('-')
            const lang = this.supportedLang.parse(splittedLocale[0])
            const region = this.supportedRegion.parse(splittedLocale[1])
            return new Locale(lang, region)
        } catch {
            return undefined
        }
    }
}
export const defaultLocale = Locale.safeParse('fr-FR')!

export type Lang = Locale['_lang']
export type Region = Locale['_region']
export type LocaleString = `${Lang}-${Region}`

const supportedLocale = z.custom<`${Lang}-${Region}`>((string: unknown) => {
    if (
        z.string().safeParse(string).success &&
        String(string).split('-').length === 2
    ) {
        const lang = String(string).split('-')[0]
        const region = String(string).split('-')[1]
        return (
            Locale.supportedLang.safeParse(lang).success &&
            Locale.supportedRegion.safeParse(region).success
        )
    }
    return false
})

export const isValidLocale = (
    string: unknown
): string is z.infer<typeof supportedLocale> => {
    return supportedLocale.safeParse(string).success
}

/**
 * Call [isValidLocale] to test string validity, if string is an invalid Locale, set [defaultLocale] to localeStorage and url
 * @param {unknow} string the string to test, trying to validate it is a Locale
 * @returns {Locale} the param string as Locale if valid, defaultLocale if string is invalid
 */
export const assertValidLocale = (string: unknown): Locale => {
    if (!isValidLocale(string)) {
        setLocaleToLocalStorage(defaultLocale)
        return defaultLocale
    }
    return Locale.safeParse(string)!
}
